import { Link } from "react-router-dom";

import { DONIRAJ_URL } from "constants/index.js"

import "./style.css";

export default function PlatformaZaDonacije() {
  const kategorije = [
    {
      ikonice: "/images/human.png",
      podnaslov: "Humanost",
      tekst:
        "Topla ruka humanosti pruža utjehu tamo gdje riječi zakažu, stvarajući vezu između srca darivatelja i primaoca.",
    },
    {
      ikonice: "/images/donacije.png",
      podnaslov: "Donacije",
      tekst:
        "Vaša donacija nije samo novac, to je izraz Vaše velikodušnosti i vjere u moć kolektivnog dobra.",
    },
    {
      ikonice: "/images/povjerenje.png",
      podnaslov: "Povjerenje",
      tekst:
        "Našu zajednicu gradimo na osnovu uzajamnog povjerenja između donatora, volontera i onih kojima pružamo pomoć.",
    },
  ];

  return (
    <div className="platforma">
      <h1 className="naslovPlatforma">
        Humanitarni fond Hilal - Vaša platforma za humanost
      </h1>
      <p className="platformText">
        Dobrodošli na Humanitarni fond "Hilal", gdje jednostavnost online
        donacija, dijeljenje humanitarnih kampanja putem digitalnih kanala i
        visok nivo sigurnosti čine osnovne karakteristike naše platforme.
      </p>
      <div className="kategorije">
        {kategorije.map((kat, index) => (
          <div key={index} className="kategorija">
            <img className="ikonice" src={kat.ikonice} alt={kat.podnaslov} />
            <h3 className="podnaslov">{kat.podnaslov}</h3>
            <p>{kat.tekst}</p>
          </div>
        ))}
      </div>
      <div className="zapocniAkcijuBtn">
        <Link to="/zapocni-akciju" className="pokreniAkciju">
          Započni akciju
        </Link>
        <Link className="pokreniAkciju" to={DONIRAJ_URL} target="_blank">Doniraj</Link>
      </div>
    </div>
  );
}
