import "./style.css";
export default function UsloviKoriscenja() {
  return (
    <div className="usloviKoriscenja">
      <h1 className="h1Uslovi">Uslovi korišćenja</h1>
      <div className="uvodUslovi uslovi">
        <p>
          Hvala što ste posjetili naš internet sajt. Vaš pristup, kao i
          korišćenje našeg internet sajta (u daljem tekstu: internet sajt)
          podliježe ovim uslovima korišćenja i važećim zakonskim propisima koji
          uređuju ovu oblast.
        </p>
        <p>
          Uz predmetne Uslove korišćenja primjenjiivaće se i odredbe Pravilnika
          o načinu prikupljanja sredstava posredstvom humanitarnog fonda Hilal
          (u daljem tekstu: Pravilnik).
        </p>
        <p>
          Uslovi korišćenja su set pravnih pravila i tehničkih uslova kojima se
          reguliše i ugovara međusobni odnos između Korisnika/Donatora i
          Fondacije sa kojim se Korisnik/Donator upoznaje i saglašava prilikom
          podnošenja zahtjeva/upućivanja donacija Fondaciji.
        </p>
        <p>Podaci o prodajnom mjestu:</p>
        <p>Naziv: Humanitarni fond Hilal</p>
        <p>E-mail: fondhilalbp@gmail.com</p>
        <p className="zadnji">
          Pristupom i korišćenjem internet sajta, prihvatate bez ograničenja,
          ove Uslove korišćenja i saglašavate se da ste se upoznali sa odredbama
          Pravilnika
        </p>
      </div>
      <div className="opsteOdredbe uslovi">
        <strong className="nasloviUslova">I Opšte odredbe</strong>
        <p>
          Sredstva za realizaciju prijavljenih akcija prikupljaju se putem SMS
          donacija, te putem uplata na žiro i devizne račune.
        </p>
        <p>
          Uplata korisniku preko interneta vrši se platnim karticama preko
          servisa NLB Banka AD Podgorica
        </p>
        <p>
          Fondacija, radi realizacije projektnih aktivnosti i prikupljanja
          sredstava, kao i finansiranja svojih aktivnosti, otvara tri vrste
          bankovnih računa: Zajednički žiro i devizni račun Fondacije
          (Zajednička kasa), Operativni žiro i devizni račun Fondacije i
          Korisnički žiro i devizni račun.
        </p>
        <p>
          Zajednička kasa Fondacije su računi na koje se prikupljaju sredstva na
          ime donacija, koja nisu usmjerena na pojedine Korisničke račune.
        </p>
        <p>
          Fondacija ima slobodu da sredstva sa Zajedničke kase raspoređuje
          ravnomjerno na sve aktivne Korisničke račune.
        </p>
        <p>
          Korisnički žiro i devizni računi su računi na koje se uplaćuju
          donacije namijenjene pojedinačnom Korisniku, prikupljene direktnim
          uplatama na isti račun ili SMS uplatama na podbroj Korisnika.
        </p>
        <p>
          Operativni žiro i devizni računi su računi koje Fondacija koristi za
          finansiranje svojih aktivnosti, a prije svega za isplatu zarada licima
          zaposlenim u Fondaciji, plaćanje eventualnih fiskalnih obaveza
          Fondacije, plaćanje usluga neophodnih za rad Fondacije, finansiranje
          marketinških i PR aktivnosti radi prikupljanja donacija i afirmacije
          rada Fondacije, plaćanje zakupa ili kupovine poslovnih prostorija
          neophodnih za rad Fondacije, i plaćanje u drugim situacijama kad je to
          neophodno da bi se obezbijedio neometan rad i razvoj Fondacije.
        </p>
        <p>
          Hardver i softver za pristupanje online servisu za donacije
          humanitarnifondhilal.me
        </p>
        <p>Sadržaj na platformi humanitarnifondhilal.me</p>
        <p>
          Online servis humanitarne organizacije humanost.com je privatno
          vlasništvo. Krajnjim korisnicima se najstrože zabranjuje objavljivanje
          ili prenošenje bilo kakvog materijala koji ugrožava ili na bilo koji
          način krši prava drugih, bilo kakav materijal koji je po svojoj
          prirodi nezakonit, uvrjedljiv, koji narušava i/ili ugrožava
          privatnost, koji je vulgaran, ili na bilo koji drugi način nepoželjan
          u odmjerenoj komunikaciji, koji na bilo koji drugi način krši bilo
          koji propis, te koji, bez izričitog, prethodno pismenog odobrenja
          humanitarne organizacije humanost.com, sadrži oglašavanje ili ponudu
          bilo čijih proizvoda i usluga. Krajnjim korisnicima se izričito
          zabranjuje da koriste ovu online platformu za oglašavanje ili
          izvođenje bilo kakve komercijalne, vjerske, političke ili
          nekomercijalne promocije.
        </p>
        <p className="zadnji">
          Rad Fondacije je transparentan i javan, i svako lice ima mogućnost da
          na zvaničnom sajtu Fondacije provjeri podatak koliko je novčanih
          sredstava ukupno prikupljeno za svakog Korisnika, kao i da li su
          uplaćena sredstva preusmjerena na način propisan Uslovima korišćenja i
          Pravilnikom.
        </p>
      </div>
      <div className="doniranjeKonverzija uslovi">
        <strong>II Doniranje i konverzija</strong>
        <p>
          Fondacija humanitarni fond Hilal koristi All-secure za online
          plaćanja.
        </p>
        <p>
          All-secure je siguran sistem za online plaćanje, plaćanje u realnom
          vremenu, kreditnim i debitnim karticama te drugim načinima plaćanja.
          All-secure kupcu i trgovcu osiguravaju siguran upis i prenos upisanih
          podataka o karticama što potvrđuje i PCI DSS certifikat koji
          All-secure ima. All-secure koristi SSL certifikat 256 bitne enkripcije
          te TLS 1.2 kriptografski protokol kao najveći stepen zaštite kod upisa
          i prenosa podataka.
        </p>
        <p>
          Novac se može uplatiti „Maestro“, „Viza“ i „Master card“ platnim
          karticama. U polje naznačeno na sajtu, potrebno je da korisnik unese
          iznos donacije koji želi da donira. Donacija i sve transakcije koje se
          obavljaju na našem sajtu vrše se u eurima (EUR). Ukoliko korisnikova
          platna kartica nije povezana sa računom u pomenutoj valuti, iznos
          transakcije u eurima biće konvertovan u valutu korisnikovog računa
          prema kursu banke kod koje je račun otvoren, odnosno izdavaoca platne
          kartice. Zbog navedenih konverzija, moguća su izvjesna odstupanja od
          originalnih iznosa transakcije. Fondacija „Ima ljudi“ je neprofitna
          organizacija i nije u sistemu PDV pa se isti ne obračunava na donirana
          sredstva.
        </p>
        <p>
          Potvrdom na odgovarajuće dugme korisnik potvrđuje unijeti iznos, nakon
          čega će biti preusmjeren na narudžbenicu. Narudžbenica sadrži pregled
          osnovnih detalja u vezi sa donacijom: iznos i podatke o naručiocu.
          Prije potvrde narudžbenice, možete odustati od doniranja ili izvršiti
          izmjene u vezi sa donacijom.
        </p>
        <p className="zadnji">
          Sigurnost podataka prilikom kupovine garantuje procesor platnih
          kartica, All-secure, pa se tako kompletan proces naplate obavlja na
          stranicama All-secure-a. Niti jednog trenutka podaci o platnoj kartici
          nisu dostupni našem sistemu.
        </p>
      </div>
      <div className="uslovi">
        <strong>III Zaštita poverljivih podataka o transakciji</strong>
        <p className="zadnji">
          Prilikom unošenja podataka o platnoj kartici, povjerljive informacije
          se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi
          upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije
          kriptografske tehnologije. Unos i prijenos ličnih podataka i podataka
          o broju kreditne kartice zaštićen je najvišim sigurnosnim standardima
          koje osigurava All-secure sistem za on-line autorizaciju kreditnih
          kartica u skladu sa zahtjevima kartičara i kartičarskih brendova te
          PCI DSS standarda. Autorizacija i naplata kreditnih kartica radi se
          korišćenjem sistema All-secure za autorizaciju i naplatu kartica u
          realnom vremenu. Pri korišćenju online servisa za donacije
          humanost.com, dužni ste se koristiti tačnim i preciznim podacima.
          Hemiias Montenegro čini sve kako bi zaštitio privatnost ličnih
          podataka. Humanost.com garantuje da podatke korisnika ne otkriva
          trećim licima, osim u slučajevima propisanim važećim zakonima naše
          zemlje i ratifikovanim međunarodnim aktima. Fondacija ne preuzima
          odgovornost po bilo kojem osnovu, ukoliko korisnik sam otkrije svoje
          podatke trećim licima.
        </p>
      </div>
      <div className="uslovi">
        <strong>IV Reklamacije</strong>
        <p className="zadnji">
          U slučaju nastanka eventualnih problema u vezi sa doniranjem, korisnik
          može izvršiti reklamaciju na e-mail adresu: fondhilalbp@gmail.com
        </p>
      </div>
      <div className="uslovi">
        <strong>V Povraćaj donacije</strong>
        <p>
          Povraćaj donacije je moguć u slučaju da je donacija izvršena
          zlopupotrebom platne kartice od strane trećih lica ili u slučaju da je
          pri uplati donacije unesen pogrešan iznos donacije, i to samo u skladu
          sa procedurom propisanom ovim Uslovima korišćenja.
        </p>
        <p>
          Potrebno je podnijeti skenirani pisani i obrazloženi zahtjev za
          povraćaj doniranog iznosa, u slobodnoj formi i potpisan od strane
          vlasnika platne kartice, i to u roku od 120 dana od izvršene donacije,
          na elektronsku adresu fondhilalbp@gmail.com .
        </p>

        <p className="zadnji">
          Uz zahtjev je neophodno dostaviti i e-mail sa potvrdom o izvršenoj
          transakciji, skeniranu ličnu kartu vlasnika kartice i kontakt telefon.
          Korisnik će biti kontaktiran od strane humanitarnog fonda Hilal u roku
          od 30 radnih dana od prijema urednog zahtjeva. Sredstva koja se
          vraćaju će humanitarni fond Hilal moći da isplati korisniku samo na
          račun koji je vezan za platnu karticu kojom je donacija izvršena.
          Fondacija je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i
          Maestro metoda plaćanja, što znači da će banka na zahtjev Fondacije
          obaviti povraćaj sredstava na račun korisnika kartice. Zahtjevi za
          povraćaj podnijeti nakon isteka roka od 120 dana od izvršene donacije
          ili na adresu različitu od adresa ovdje navedenih, neće biti
          razmatrani.
        </p>
      </div>
      <div className="uslovi">
        <strong>VI Važenje i izmjene uslova korišćenja</strong>
        <p className="zadnji">
          Humanitarni fond Hilal zadržava pravo da u bilo koje doba izvrši
          izmenu Uslova korišćenja. Uslovi korišćenja stupaju na snagu danom
          objavljivanja na sajtu humanitarnifondhilal.me.
        </p>
      </div>
    </div>
  );
}
