import "react-responsive-carousel/lib/styles/carousel.min.css";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';

import { useAkcije } from "context/akcije"; // Dodaj useAkcije

import { getActionById } from "api/actions.js"

import "./style.css"

const DetaljiAkcije = () => {
  const { id } = useParams();

  const [detaljiAkcije, setDetaljiAkcije] = useState({});
	const [loading, setLoading] = useState(true)

	const fetchAkcijaById = async () => {
		const { action, message } = await getActionById(id)

		if(action) {
			setDetaljiAkcije(action)
		}

		setLoading(false)
	}

	useEffect(() => {
		fetchAkcijaById()
	}, [])

	if(!Object.keys(detaljiAkcije).length && loading) {
		return <p className="toMiddle">Učitavanje...</p>
	} else if(!Object.keys(detaljiAkcije).length && !loading) {
		return <p className="toMiddle">Nepostojeća akcija</p>
	}

  return (
    <div>
      <div className="detaljiAkcijeBody">
        <h2 className="detaljiAkcijeNaslov">{detaljiAkcije?.actionName}</h2>
        <div className="container-detaljiAkcije">
          <img className="naslovnaSlikaAkcije" src={detaljiAkcije?.featuredPhoto} />
          <p className="detaljiAkcijeTekst">{detaljiAkcije?.description}</p>
        </div>

        <div>
          <h2 className="galerijaNaslov">Galerija</h2>
					<div className="galerija">
            <Carousel>
              {detaljiAkcije.photos.map((slika, index) => (
                <img
                  className="slikeGalerije"
                  key={index}
                  src={slika}
                  alt={`Slika ${index + 1}`}
                />
              ))}
            </Carousel>
					</div>
        </div>
      </div>
    </div>
  );
};

export default DetaljiAkcije;
