import { useState } from "react";
import React, { useRef } from "react";
import { nanoid } from "nanoid"

import UploadFileComponent from "components/upload-file-component"
import LicniPodaciAkcija from "components/licni-podaci-akcija"

import notify from "utils/notify.js"

import { uploadFiles } from "api/upload.js"
import { createAction } from "api/actions.js"

import "./style.css"

const pokretacAkcijePodaci = {
	pravnoLice: {
		naslov: "Organizacija",
		ime: "NAZIV ORGANIZACIJE",
		adresa: "SJEDIŠTE ORGANIZACIJE, GRAD I DRŽAVA",
		kontakt: "WEB STRANA",
	},
	fizickoLice: {
		naslov: "Lični podaci",
		ime: "IME I PREZIME",
		adresa: "ADRESA",
		kontakt: "KONTAKT TELEFON",
	}
}

const requiredFields = [
	"initiatorName",
	"initiatorAddress",
	"initiatorEmail",
	"initiatorBankAccount",
	"actionName",
	"targetSum",
	"actionDomain",
	"startDate",
	"endDate",
	"description",
	"timeToImplementAfterFunding",
	"termsAndCondition",
	"identityConfirmationDocuments",
	"photos",
]

const requiredFieldsPravnoLice = [
	"initiatorContactName",
	"initiatorContactPhone",
	"initiatorContactEmail",
]

const fieldsMapping = {
  "initiatorName": "Ime i Prezime",
  "initiatorAddress": "Adresa",
  "initiatorEmail": "E-mail",
  "initiatorBankAccount": "Žiro Račun",
  "actionName": "Naziv Akcije",
  "targetSum": "Željena sredstva",
  "actionDomain": "Oblast Akcije",
  "startDate": "Datum Pokretanja Akcije",
  "endDate": "Datum Završetka Akcije",
  "description": "Zašto Pokrećete Akciju",
  "timeToImplementAfterFunding": "Vrijeme Realizacije",
  "termsAndCondition": "Uslovi Korišćenja",
  "identityConfirmationDocuments": "Dokument koji potvrđuje identitet",
  "photos": "Fotografije",
  "initiatorContactName": "Naziv Organizacije",
  "initiatorContactPhone": "Sjedište Organizacije",
  "initiatorContactEmail": "E-Mail",
}

export default function ZapocniAkciju() {
	const [currentTab, setCurrentTab] = useState("fizickoLice")
	const [payload, setPayload] = useState({})

	const handleInputChange = (value, key) => {
		setPayload(prevState => ({
			...prevState,
			[key]: value,
			...(key === "targetSum" ? { targetSumWithTax: parseFloat((+value + (+value * 0.09) )).toFixed(2) } : {}),
		}))
	}

  const handleButton1Click = () => {
		setCurrentTab("fizickoLice")
  };

  const handleButton2Click = () => {
		setCurrentTab("pravnoLice")
  };

	const createActionHandler = async () => {
		for(const key of requiredFields) {
			if(!payload[key]) {
        notify(`Popunite sva obavezna polja! ${fieldsMapping[key] || key}`, "warn")
				return;
			} else if(Array.isArray(payload[key]) && !payload[key].length) {
        notify(`Popunite sva obavezna polja! ${fieldsMapping[key] || key}`, "warn")
				return;
			}
		}

		if(currentTab === "pravnoLice") {
			for(const key of requiredFieldsPravnoLice) {
				if(!payload[key]) {
          notify(`Popunite sva obavezna polja! ${fieldsMapping[key] || key}`, "warn")
					return;
				}
			}
		}

		const customHash = nanoid(10)

		const uploadedPhotos = await uploadFiles(payload.photos, customHash)
		const uploadedDocuments = await uploadFiles(payload.identityConfirmationDocuments, customHash)

		const data = await createAction({
			...payload,
			targetSum: parseFloat(payload.targetSum),
			photos: uploadedPhotos.join(','),
			identityConfirmationDocuments: uploadedDocuments.join(','),
			customHash,
		})

		if(data?.message === "Successfully created") {
			window.location.href = "/"
		} else {
      notify(data?.message || 'Došlo je do greške', "error")
		}
	}

  return (
    <div>
      <div className="pokreniAkciju-container">
        <h1>Započni akciju</h1>

        <div className="buttoni">
          <button
            className={currentTab === "pravnoLice" ? "click1" : "button1"}
            onClick={handleButton1Click}
          >
            {" "}
            Fizičko lice
          </button>
          <button
            className={currentTab === "fizickoLice" ? "click2" : "button2"}
            onClick={handleButton2Click}
          >
            Pravno lice
          </button>
        </div>

        <div className="pravnoLice">
					<h2 className="sectionTitle">{pokretacAkcijePodaci[currentTab]?.naslov}</h2>
          <div className="organizacija">
            <div className="inputiDiv">
              <p className="inputInfo">
								{pokretacAkcijePodaci[currentTab]?.ime}<span>*</span>
              </p>{" "}
							<input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorName")}></input>
            </div>
            <div className="inputiDiv">
              <p className="inputInfo">
								{pokretacAkcijePodaci[currentTab]?.adresa}<span>*</span>
              </p>{" "}
              <input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorAddress")}></input>
            </div>
            <div className="inputiDiv">
              <p className="inputInfo">
                EMAIL<span>*</span>
              </p>{" "}
              <input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorEmail")}></input>
            </div>
            <div className="inputiDiv">
              <p className="inputInfo">{pokretacAkcijePodaci[currentTab]?.kontakt}<span>*</span></p>{" "}
              <input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorPhoneOrWebPage")}></input>
            </div>
            <div className="inputiDiv">
              <p className="inputInfo">FACEBOOK STRANA</p>{" "}
              <input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorFacebook")}></input>
            </div>
            <div className="inputiDiv">
              <p className="inputInfo">INSTAGRAM STRANA</p>{" "}
              <input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorInstagram")}></input>
            </div>
            <div
              className="inputiDiv inputOrganizacijaPodaciSedmiInput"
            >
              <p className="inputInfo">
                ŽIRO RAČUN<span>*</span>
              </p>{" "}
              <input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorBankAccount")}></input>
              <p className="objasnjenje">
                Nakon odobravanja akcije, sredstva se prikupljaju putem
                zvaničnog računa Fondacije. Nakon završetka akcije i
                prikupljanja sredstava, Fondacija će, na Pravilnikom propisan
                način, izvršiti prenos sredstava na žiro račun Korisnika koji se
                navede u ovom polju.
              </p>
            </div>
          </div>
					{
						currentTab === "pravnoLice" ? 
							<>
								<h2 className="sectionTitle">Kontakt osoba</h2>
								<div className="organizacija">
									<div className="inputiDiv">
										<p className="inputInfo">
											KONTAKT OSOBA<span>*</span>
										</p>{" "}
										<input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorContactName")}></input>
									</div>
									<div className="inputiDiv">
										<p className="inputInfo">
											KONTAKT TELEFON<span>*</span>
										</p>{" "}
										<input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorContactPhone")}></input>
									</div>
									<div className="inputiDiv">
										<p className="inputInfo">
											EMAIL<span>*</span>
										</p>{" "}
										<input className="inputOrganizacijaPodaci" onChange={e => handleInputChange(e?.target?.value, "initiatorContactEmail")}></input>
									</div>
								</div>
							</>
						: null
					}
        </div>

        <LicniPodaciAkcija handleInputChange={handleInputChange} payload={payload} />
				<UploadFileComponent handleInputChange={handleInputChange} />
				<div className="uploadBtnDiv">
					<button className="uploadBtn" onClick={createActionHandler}>Prijavi zahtjev</button>
				</div>
      </div>
    </div>
  );
}
