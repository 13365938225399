import { Link } from "react-router-dom";

import "./style.css"

export default function AkcijaCard({ akcija }) {
	if(!akcija) return null

	return (
		<Link to={`https://humanitarnifondhilal.me/akcija/${akcija.id}`}>
			<div className="divZavrseneAkcije">
        <div className="slikaAkcije">
          <img src={akcija.featuredPhoto} alt={"Slika"} />
        </div>
				<div className="divIspodSlike">
					<div className="naslovSlike">
						{akcija.actionName}
					</div>
					<p className="opisZavrseneAkcije">{akcija.description?.slice(0, 200)}{akcija.description?.length > 200 ? "..." : ""}</p>
					<div className="pregledajBtn">
						Pregledaj
					</div>
				</div>
			</div>
		</Link>
	)
};
