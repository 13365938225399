import OpisFonda from "components/opis-fonda"
import TrenutneAkcije from "components/trenutne-akcije"
import PlatformaZaDonacije from "components/platforma-za-donacije"

export default function Home() {
  return (
    <>
      <TrenutneAkcije />
      <OpisFonda />
      <PlatformaZaDonacije />
    </>
  );
}
