import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AkcijaCard from "components/akcije-card";

import { getActionsByType } from "api/actions.js";

import "./style.css";

export default function TrenutneAkcije() {
  const [akcije, setAkcije] = useState([]);

  const fetchAkcije = async () => {
    const { actions = [], message } = await getActionsByType("finished", 5);
    const { actions: activeActions } = await getActionsByType("active", 5);

    if (actions?.length || activeActions?.length) {
      setAkcije(actions.concat(activeActions || []));
    }
  };

  useEffect(() => {
    fetchAkcije();
  }, []);

  return (
    <>
      <div className="quote-container">
     
        <img src="/images/peoples.png" alt="" className="peoples" />


        <div className="overlay">
          <div className="textContainer">
          <p className="text1">˝Što god da podijelite,<br/> On će vam nadoknaditi,<br/>  jer On je najbolji opskrbnik˝</p>
          <p className="ajet">[Sebe´, 39, ajet]</p>
          </div>
        </div>
      </div>
      <div className="trenutneAkcije">
        <h1 className="h1TrenutneAkcije">Novosti</h1>

        <div className="zavrseneAkcije">
          <div className="container-zavrseneAkcije">
            {akcije.map((akcija, index) => (
              <AkcijaCard key={index} akcija={akcija} />
            ))}
          </div>
        </div>

        <Link to="/akcije#trenutneAkcije" className="akcijeBtn linkBtn">
          Pogledajte ostale akcije
        </Link>
      </div>
    </>
  );
}
