import { BACKEND_HOST } from "constants/index.js"

export async function getLastDonations(limit) {
	try {
		const response = await fetch(`${BACKEND_HOST}/api/get-donations`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
		})

		const data = await response.json()

		if(data?.donations) {
			return {
				donations: data.donations.slice(0, limit || data.donations.length)
			}
		}

		return data
	} catch(e) {}

	return {
		actions: []
	}
}

