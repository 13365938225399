import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AkcijeProvider, useAkcije } from "context/akcije";

import DonirajContainer from "components/doniraj";
import AkcijaCard from "components/akcije-card"

import { getActionsByType } from "api/actions.js"

import "./style.css"

const ZavrseneAkcije = () => {
	const [akcije, setAkcije] = useState([])

	const fetchAkcije = async () => {
		const { actions, message } = await getActionsByType("finished")

		if(actions?.length) {
			setAkcije(actions)
		}
	}

	useEffect(() => {
		fetchAkcije()
	}, [])

  if (!akcije) {
    return <p>Učitavanje...</p>;
  }

  return (
    <AkcijeProvider>
      <div className="komponentaZavrseneAkcije">
        <div className="zavrseneAkcije">
          <h1 className="naslovZavrseneAkcije">Završene akcije</h1>
          <div className="container-zavrseneAkcije">
            {akcije.map((akcija, index) => (
              <AkcijaCard key={index} akcija={akcija} />
            ))}
          </div>
        </div>
        <DonirajContainer />
      </div>
    </AkcijeProvider>
  );
};

export default ZavrseneAkcije
