import { BsTelephoneFill } from "react-icons/bs";
import { BiLogoFacebookSquare } from "react-icons/bi";
import { IoMdMail } from "react-icons/io";
import { BsYoutube } from "react-icons/bs";
import { AiTwotoneTool, AiOutlineCopyrightCircle, AiFillInstagram } from "react-icons/ai";

import "./style.css"

export default function Footer() {
  return (
    <div className="glavniFooter">
      <footer className="footer">
        <div className="footer1">
          <div className="div11">
            <a href="mailto:fondhilalbp@gmail.com">
              <IoMdMail />
							<span>fondhilalbp@gmail.com</span>
            </a>
            <a href="tel:+38269557371">
              <BsTelephoneFill /> 
							<span>+382 69 557 371</span>
            </a>
          </div>
          <div className="div12">
            {[
              {
                href: "https://www.facebook.com/HumanitarniFondHilal/",
                icon: <BiLogoFacebookSquare className="drustveneMreze" />,
              },
              {
                href: "https://www.instagram.com/humanitarnifondhilal/",
                icon: <AiFillInstagram className="drustveneMreze" />,
              },
              {
                href: "https://www.youtube.com/@humanitarnifondhilal6514",
                icon: <BsYoutube className="drustveneMreze" />,
              },
            ].map((link, index) => (
              <a key={index} href={link.href}>
                {link.icon}
              </a>
            ))}
          </div>
          <div className="div13">
            <a>
              <AiOutlineCopyrightCircle />
							<span>2012 Humanitarni fond Hilal</span>
            </a>
            <a href="https://www.ikraweb.me">
              <AiTwotoneTool />
							<span>ikraweb.me</span>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
