import { useEffect, useState } from "react"
import { getLastDonations } from "api/donations.js"

import "./style.css"

const PER_PAGE = 10

export default function UkupnoDonacije() {
	const [page, setPage] = useState(1)
	const [donations, setDonations] = useState([])

	const fetchDonations = async () => {
		const { donations, message } = await getLastDonations(300)

		if(donations?.length) {
			setDonations(donations)
		}
	}

	useEffect(() => {
		fetchDonations()
	}, [])

	const increasePage = () => {
		const totalPages = Math.ceil(donations?.length / PER_PAGE)

		if(page < totalPages && page > 0) {
			setPage(prevState => prevState + 1)
		} else if(page < 1) {
			setPage(1)
		}
	}

	const decreasePage = () => {
		if(page > 1) {
			setPage(prevState => prevState - 1)
		} else if(page !== 1) {
			setPage(1)
		}
	}

  return (
    <div className="donacije">
      <h1 className="donacijeNaslov">Donacije</h1>
      <h3 className="sakupljeneDonacije">Sakupljene donacije:</h3>
      <p className="tekstDonacije">
        Donacije podrazumevaju račun putem kojeg se prikupljaju, a zatim i
        raspodeljuju sredstva za sve aktivne akcije, odnosno korisnike. Donacije
        imaju za cilj da pomognu korisnicima da efikasnije prikupe sredstva za
        svoju akciju.
      </p>

      <h2>Kartice/Uplate</h2>
      <table>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Ime i Prezime</th>
            <th>Iznos uplate</th>
          </tr>
        </thead>
        <tbody>
          {donations?.slice(((page * PER_PAGE) - PER_PAGE), page * PER_PAGE).map((donation, index) => (
            <tr key={index}>
              <td>{donation.createdAt}</td>
              <td>{donation.donatorName}</td>
              <td>{donation.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
			<div className="pagination">
				<div onClick={decreasePage}><span>❮</span> <span>Prethodna stranica</span></div>
				<div onClick={increasePage}><span>Sljedeća stranica</span> <span>❯</span></div>
			</div>
    </div>
  );
}
