import React, { createContext, useContext } from "react";

const AkcijeContext = createContext();

const AkcijeProvider = ({ children }) => {
  const akcije = [
    {
      to: "/akcija/1",
      imgSrc: "/images/akcija1/naslovnaAckija1.webp",
      naslov: "Dostavljena pomoć za sedamnaest porodica i studente",
      opis: "Naši volonteri i prijatelji gotovo svakodnevno su prisutni na terenu dostavljajući onu najhitniju pomoć. Tim povodom, obezbeđena je pomoć za sedamnaest porodica iz Bijelog Polja i Petnjice...",
      detaljanOpis: `
      Naši volonteri i prijatelji gotovo svakodnevno su prisutni na terenu dostavljajući onu najhitniju pomoć. Tim povodom, obezbeđena je pomoć za sedamnaest porodica iz Bijelog Polja i Petnjice. Pomoc za pomenute porodice ogledala se u vidu prehrambenih pakate, plus hemija. U ovom dijelu kod dostave pomoci u Petnjici imali smo ucesce nasih prijatelja Rejhana Ramcilovica i Eldara Adrovica, inace nasa stalna ekipa za sve aktivnosti na teritoriji Petnjice. Sredstva za ovu pomoc obezbijedjena su kroz akciju Srcem za Crnu Goru. Pored pomenute pomoci, nasi donatori Arijan Erovic iz Luksemburga,i familija Hajriza Brcvaka i Almina Catovica sa sestrama, obezbijedili su ogrijevna drva za dvije porodice, kao i stipendije za pet studenta. Smatramo da je ulaganje u znanje trajni vakuf, od kojeg mnogi ljudi imaju koristi. Ulaganje u znanje je nesto na cemu smo kroz nas humanitaran rad posebno opredijeljeni. Zahvaljujemo se svim donatorima koji prate nas rad i ucestvuju u humanim misijama. Kabul da bude amin. #Hemiias🤝#Hilal🤝#BudiHuman
    `,
      slike: [
        "/images/akcija1/slika1.webp",
        "/images/akcija1/slika2.webp",
        "/images/akcija1/slika3.webp",
        "/images/akcija1/slika4.webp",
        "/images/akcija1/slika5.webp",
        "/images/akcija1/slika6.webp",
        "/images/akcija1/slika7.webp",
        "/images/akcija1/slika8.webp",
        "/images/akcija1/slika9.jpg",
      ],
    },
    {
      to: "/akcija/2",
      imgSrc: "/images/szcg.jpg",
      naslov: "Srcem za Crnu Goru",
      opis: "Akcija Srcem za Crnu Goru se nastavlja U prilogu danasnji radovi na izgradnji kuce porodice Tiganj iz Rozaja. Radna ekipa uspjela je zavrsiti krovnu konstrukciju i prekriti je...",
      detaljanOpis: `Akcija Srcem za Crnu Goru se nastavlja
      U prilogu danasnji radovi na izgradnji kuce porodice Tiganj iz Rozaja. Radna ekipa uspjela je zavrsiti krovnu konstrukciju i prekriti je. 
      
      U okviru ove akcije nastavlja se izgradnja kuca za jos dvije porodice. 
      #Hemiias🤝#BudiHuman🤝#Hilal
      
      Hvala svim donatorima koji su ispostovali svoje obecane donacije🤝👌`,

      slike: [
        "/images/akcija2/slika1.jpg",
        "/images/akcija2/slika2.jpg",
        "/images/akcija2/slika3.webp",
        "/images/akcija2/slika4.webp",
        "/images/akcija2/slika5.webp",
        "/images/akcija2/slika6.webp",
      ],
    },

    {
      to: "/akcija/3",
      imgSrc: "/images/10porodica.jpg",
      naslov: "Pomoć za deset porodica",
      opis: "U skladu sa planiranim aktivnostima, nastojimo pomoc dostaviti na adrese koje trebaju pomoc. Akcija Srcem za Crnu Goru realizovana prosle godine, akumulirala je dio stedstava za hranu, potreban porodicama loseg imovnog stanja...",
      detaljanOpis: `
      
      U skladu sa planiranim aktivnostima, nastojimo pomoc dostaviti na adrese koje trebaju pomoc. Akcija Srcem za Crnu Goru realizovana prosle godine, akumulirala je dio stedstava za hranu, potreban porodicama loseg imovnog stanja. Ekipe Hemiiasa, Hilala i Budi human nastoje realizovati pomoc. Prethodna dva dana pomoc u paketina hrane je obezbijedjena za deset porodica sa pretezno seoskih podrucja opstine Bijelo Polje. 
      Takodje, realizovana je i pomoc za cetriri studenta u vidu mjesecnih stipendija koje je obezbijedila fondacija Hemiis. 
      Gotovo svakodnevnim prisustvom na terenu uvidjamo koliko je bitna humanost ljudi, za opstanak i laksi zivot mnogi porodica u nasoj opstini, ali i drugim opstinama nase drzave.
      Zahvaljujemo se svim donatorima koji su svoje sadake udijelili kroz ovu akciju. Kabul da bude, amin.🤝🤝🤝`,
      slike: [
        "/images/akcija3/slika1.jpg",
        "/images/akcija3/slika2.jpg",
        "/images/akcija3/slika3.jpg",
        "/images/akcija3/slika4.jpg",
      ],
    },
    {
      to: "/akcija/4",
      imgSrc: "/images/karavan.jpg",
      naslov: "Karavan dobročinstva se nastavlja",
      opis: "Danas je Hilal ekipa volontera obisla je četiri porodice iz naše opštine. Donator Sanel Sajo Crnovršanin iz Njemačke želio je svojom pažnjom makar malo olakšati svakodnevnicu za četiri porodice...",
      detaljanOpis: `

    Danas je Hilal ekipa volontera obisla je četiri porodice iz naše opštine. Donator Sanel Sajo Crnovršanin iz Njemačke želio je svojom pažnjom makar malo olakšati svakodnevnicu  za četiri porodice. Ovakvim humanim gestovima pokazujemo da humanost i dalje stanuje u srcima mnogih dobrotvora koji su dio karavane Hilal dobročinstva već gotovo dvanaest godina. 
    Poseban trag na nas ostavila je naša Senka koja nam je poznata već više od 8 godina. Veliku ulogu u pomaganju ovakvih familija igra uža i šira porodica, i đe su rezultati pomaganja vrlo vidni. 
    Zahvaljujemo se svim humanistima koji prate Hilal misiju dobročinstva. Allah da nagradi Sanela i upiše na dobro,amin. 🤝🤲`,
      slike: [
        "/images/akcija4/slika1.jpg",
        "/images/akcija4/slika2.jpg",
        "/images/akcija4/slika3.jpg",
      ],
    },
    {
      to: "/akcija/5",
      imgSrc: "/images/akcija5.jpg",
      naslov: "Donacija za školarce i djecu jetime",

      opis: "  Ekipe volontera fonda Hilal i fondacije Hemiias u prethodnim danima realizovale su mnoge humanitarne akcije.  Posebnost u darivanju ovih dana su naši najmladji i najljepsi. Esad Banda sa porodicom, Amel Softic sa porodicom, Ahmet Hamidovic i Amer Hajdarpašić obezbijedili su...",
      detaljanOpis: `
      
      Ekipe volontera fonda Hilal i fondacije Hemiias u prethodnim danima realizovale su mnoge humanitarne akcije.  Posebnost u darivanju ovih dana su naši najmladji i najljepsi. Esad Banda sa porodicom, Amel Softic sa porodicom, Ahmet Hamidovic i Amer Hajdarpašić obezbijedili su sredstva za 23 djece jetima (siročadi) iz Bijelog Polja i Pljevalja kojim je kupljena garderoba za početak ove školske godine. 
      S tim u vezi obezbijedjen je školski pribor za 19 djece iz B.Polja i Petnjce. Za ovaj lijep poklon pobrinula se knjižara Miral doo iz Bara. Izdvajamo i porodicu Hasanagić iz Njemačke koja je preuzela brigu o dva jetima, kojima će u toku školske godine uplaćivati sredstva u iznosu od 30 eura po djetetu mjesečno. Porodica Hasanagić naglasila je da će u toku godine preuzeti obavezu pomaganja još jednog jetima. 
      Pomoć u paketima hrane za one najugroženije sporovodi se gotovo svakodnevno. 
      Kroz samo nekoliko ovakvih primjera i aktivnosti možemo reći da merhamet i dalje stanuje u srcima ljudi ovog vremena, te da njihova pomoć dolazi na mnoge kućne adrese koje je trebaju. 
      Molimo Allaha da nagradi donatore svakim dobrom i upiše na hair. Amin
      #Hemiias🤝#Hilal`,
      slike: [
        "/images/akcija5/slika1.jpg",
        "/images/akcija5/slika2.jpg",
        "/images/akcija5/slika3.jpg",
        "/images/akcija5/slika4.jpg",
      ],
    },
  ];

  return (
    <AkcijeContext.Provider value={akcije}>{children}</AkcijeContext.Provider>
  );
};

const useAkcije = () => {
  return useContext(AkcijeContext);
};

export { AkcijeProvider, useAkcije };
