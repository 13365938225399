import { useState } from "react"
import { Link } from "react-router-dom";

import { DONIRAJ_URL } from "constants/index.js"

import "./style.css"

const nav = [
	{ name: "Akcije", to: "/akcije" },
	{ name: "Završene akcije", to: "/zavrsene-akcije" },
	{ name: "Započni akciju", to: "/zapocni-akciju" },
	{ name: "Opis fonda", to: "/opis-fonda" },
]

export default function Header() {
  const [isMenu2Visible, setMenu2Visible] = useState(false);

  const iconData = [
    { src: "/icons/me.png", alt: "Crna Gora" },
    { src: "/icons/en.png", alt: "Engleski" },
    { src: "/icons/de.svg", alt: "Nemački" },
  ];

  const toggleMenu2 = () => {
    setMenu2Visible(!isMenu2Visible);
  };

  return (
    <header className="header">
      <div className="medzlis">
        <Link to="/">
          <img className="logo1" src="/images/logo.png" alt="Logo" />
        </Link>
        <p>Medžlis islamske zajednice Bijelo Polje</p>
      </div>
      <img onClick={toggleMenu2} src="/icons/menu.png" className="menu" />

      <div className={`menu2 ${isMenu2Visible ? "visible" : ""}`} onClick={toggleMenu2}>
				<div className="aktiviraniMeni" onClick={e => e.stopPropagation()}>
					<div className="medju-meni">
						<img
							src="/icons/right.png"
							className="closeBtn"
							onClick={toggleMenu2}
						/>
						{nav.map(
							(link, index) => (
								<Link key={index} to={link.to} className="link" onClick={toggleMenu2}>
									{link.name}
								</Link>
							)
						)}

						<Link to={DONIRAJ_URL} target="_blank" className="btn11" onClick={toggleMenu2}>
							Doniraj
						</Link>
					</div>

          <div className="iconss">
            {iconData.map((icon, index) => (
              <Link key={index} to="#">
                <img src={icon.src} alt={icon.alt} className="round-icon" />
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className="secDiv">
					{nav.map(
						(link, index) => (
							<Link key={index} to={link.to} className="link">
								{link.name}
							</Link>
						)
					)}

					<Link to={DONIRAJ_URL} target="_blank" className="btn1">
						Doniraj
					</Link>

        <div className="icons">
          {iconData.map((icon, index) => (
            <Link key={index} to="#">
              <img src={icon.src} alt={icon.alt} className="round-icon" />
            </Link>
          ))}
        </div>
      </div>
    </header>
  );
}
