import { useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { ToastContainer, Slide } from 'react-toastify';

import Header from "components/header"
import Footer from "components/footer"

import Home from "pages/home"
import Opis from "pages/opis-fonda"
import OtvoreneAkcije from "pages/otvorene-akcije"
import Payment from "pages/payment"
import UsloviKoriscenja from "pages/uslovi-koriscenja"
import ZapocniAkciju from "pages/zapocni-akciju"
import Doniraj from "pages/doniraj"
import ZavrseneAkcije from "pages/zavrsene-akcije"
import DetaljiAkcije from "pages/detalji-akcije"

import { AkcijeProvider } from "context/akcije";

import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <AkcijeProvider>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Slide}
        />
				<Header />
				<div className="body-container">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/opis-fonda" element={<Opis />} />

						<Route path="/akcije" element={<OtvoreneAkcije />} />
						<Route path="/doniraj" element={<Doniraj />} />
						<Route path="/zavrsene-akcije" element={<ZavrseneAkcije />} />
						<Route path="/akcija/:id" element={<DetaljiAkcije />} />
						<Route path="/zapocni-akciju" element={<ZapocniAkciju />} />


						<Route path="/payment" element={<Payment />} />

						<Route path="/uslovi-koriscenja" element={<UsloviKoriscenja />} />
					</Routes>
				</div>
				<Footer />
      </BrowserRouter>
    </AkcijeProvider>
  );
}
