import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DonirajContainer from "components/doniraj"
import UkupnoDonacije from "components/ukupno-donacije"

import AkcijaCard from "components/akcije-card"

import notify from "utils/notify.js"

import { getActionsByType } from "api/actions.js"

import "./style.css"

export default function OtvoreneAkcije() {
  const navigate = useNavigate();

  const [iznos, setIznos] = useState("");
	const [akcije, setAkcije] = useState([])

  const handleDonirajClick = () => {
    if (!iznos.trim()) {
      notify("Morate unijeti iznos", "warn");
    } else if (isNaN(Number(iznos))) {
      notify("Iznos mora sadržavati samo brojeve", "warn");
    } else {
      // Navigacija na novu stranicu s parametrima iznosa i valute
      navigate(`/payment?iznos=${iznos}`);
    }
  };

	const fetchAkcije = async () => {
		const { actions = [], message } = await getActionsByType("active")
    const { actions: finishedActions } = await getActionsByType("finished")

		if(actions?.length || finishedActions?.length) {
			setAkcije(actions.concat(finishedActions || []))
		}
	}

	useEffect(() => {
		fetchAkcije()
	}, [])

  return (
    <div className="akcije">
      <div className="trenutneAkcije ">
        <h1>Akcije</h1>
      </div>

			<div className="zavrseneAkcije">
				<div className="container-zavrseneAkcije">
					{akcije.map((akcija, index) => (
						<AkcijaCard key={index} akcija={akcija} />
					))}
				</div>
			</div>

      <DonirajContainer />
      <UkupnoDonacije />
    </div>
  );
}
