import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";

import { DONIRAJ_URL } from "constants/index.js"

import notify from "utils/notify.js"

import "./style.css";

export default function DonirajContainer() {
  const navigate = useNavigate();
  const [iznos, setIznos] = useState("");

  const handleDonirajClick = () => {
    return;
    notify("Funkcija će uskoro biti dodata...", "warn")
    if (!iznos.trim()) {
      notify("Morate unijeti iznos", "warn");
    } else if (isNaN(Number(iznos))) {
      notify("Iznos mora sadržavati samo brojeve", "warn");
    } else {
      navigate(`/payment?iznos=${iznos}`);
    }
  };

  const items = [
    {
      title: "Uplati karticom",
      icon: "/icons/card.png",
      placeholder: "Unesite iznos",
      buttonText: "Doniraj",
      onClick: handleDonirajClick,
      inputType: "number",
    },
    {
      title: "Uplati na račun",
      icon: "/icons/cardInfo.png",
      content: (
        <label className="uplatiNaRacun">
          <p className="naslov">Žiro račun</p>
          <strong>540-12762-77</strong>
          <p className="imeBanke">Erste banka</p>
        </label>
      ),
    },
    {
      title: "Uplate iz inostranstva",
      icon: "/icons/cardInfo.png",
      content: (
        <>
          <strong className="islamskaZaj">
          Islamska zajednica u Crnoj Gori
          </strong>
          <label>
            <p className="naslov">IBAN</p>
            <strong>ME25540000007300773045</strong>
          </label>
          <label>
            <p className="naslov">SWIFT</p>
            <strong>OPPOMEPG</strong>
          </label>
          <p>Sa napomenom: ZA HILAL</p>
        </>
      ),
    },
    {
      title: "Pošalji SMS",
      icon: "/images/sms.png",
      content: <p className="uskoro">Uskoro...</p>,
    },
  ];

  return (
    <div className="glavniDoniraj">
      <h1>Doniraj</h1>
      <div className="doniraj">
        {items.map((item, index) => (
          <div key={index} className="uplate">
            <div className="divHead">
              <h4>{item.title}</h4>
              <img src={item.icon} className="cardInfo" />
            </div>

            <div className="info">
              {item.placeholder && (
                <span className="inputContainer">
                  <input
                    placeholder={item.placeholder}
                    className="inputDoniraj"
                    value={iznos}
                    type={item.inputType || "text"}
                    onChange={(e) => setIznos(e.target.value)}
                  />
                  <span>€</span>
                </span>
              )}
              {item.content}
              {item.buttonText && (
                <Link to={DONIRAJ_URL} target="_blank">
                  <button className="btnDoniraj" onClick={item.onClick}>
                    {item.buttonText}
                  </button>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
