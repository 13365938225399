import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import validator from "validator";
import { Link } from "react-router-dom";
import Cards from 'react-credit-cards';

import {
	formatCreditCardNumber,
  formatCVV,
  formatExpirationDate,
} from "utils/index.js"

import 'react-credit-cards/es/styles-compiled.css';
import "./style.css"

export default function Payment() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const iznos = queryParams.get("iznos") || 100;

	const [payload, setPayload] = useState({})
	const [error, setError] = useState({})

  const handleInputChange = (event, key) => {
		let value = event.target.value

		if (key === "brojKartice") {
      value = formatCreditCardNumber(value);
    } else if (key === "validnaDo") {
      value = formatExpirationDate(value);
    } else if (key === "cvv") {
      value = formatCVV(value);
    }

		setPayload(prevState => ({
			...prevState,
			[key]: value
		}))
  };

  const handlePlatiClick = () => {
    const errors = {};

		["imePrezime", "brojKartice", "validnaDo", "cvv", "isChecked"].forEach(key => {
			if(payload[key]?.trim() === "" || !payload[key]) {
				errors[key] = "Obavezno polje!"
			}
		})

    if (!payload.isChecked) {
      errors.checkbox = "Morate prihvatiti uslove korišćenja";
    }

		if(Object.keys(errors).length) {
			setError(errors)
		}
  };

	const handleInputFocus = ({ target }) => {
		setPayload(prevState => ({
			...prevState,
			focused: target.name
		}))
  };

  return (
    <div className="payment">
			<h1 className="iznosDonacije">Iznos donacije <span>{iznos}€</span></h1>

      <div>
        <h4 className="licniPodaciVlasnika">Podaci sa kartice</h4>
      </div>
      <div className="fcontainer">
				<span>
					<Cards
						cvc={payload.cvv || ""}
						expiry={payload.validnaDo || ""}
						focused={payload.focused || ""}
						name={payload.imePrezime || ""}
						number={payload.brojKartice || ""}
					/>
				</span>
				<div>
					<div>
						<p className="inputInfo">
							BROJ KARTICE <span>*</span>
						</p>
						<input
							type="tel"
							name="number"
							required
							pattern="[\d| ]{16,22}"
							value={payload.brojKartice}
							onFocus={handleInputFocus}
							onChange={(event) =>
								handleInputChange(event, "brojKartice")
							}
						/>
						{error.brojKartice && <p className="errorMessage">{error.brojKartice}</p>}
					</div>
					<div>
						<p className="inputInfo">
							IME I PREZIME <span>*</span>
						</p>
						<input
							type="text"
							required
							name="name"
							onFocus={handleInputFocus}
							value={payload.imePrezime}
							onChange={(event) =>
								handleInputChange(event, "imePrezime")
							}
						/>
						{error.imePrezime && <p className="errorMessage">{error.imePrezime}</p>}
					</div>
				</div>

				<div>
					<div>
						<p className="inputInfo">VALIDNA DO <span>*</span></p>
						<input
							id="validnaDo"
							type="tel"
							name="expiry"
							required
							pattern="\d\d/\d\d"
							value={payload.validnaDo}
							onFocus={handleInputFocus}
							onChange={(event) => handleInputChange(event, "validnaDo")}
						/>
						{error.validnaDo && <p className="errorMessage">{error.validnaDo}</p>}
					</div>

					<div>
						<p className="inputInfo">
							CVV <span>*</span>
						</p>
						<input
							id="cvv"
							type="tel"
							name="cvc"
							onFocus={handleInputFocus}
							pattern="\d{3,4}"
							required
							value={payload.cvv}
							onChange={(event) =>
								handleInputChange(event, "cvv")
							}
						/>
						{error.cvv && <p className="errorMessage">{error.cvv}</p>}
					</div>
				</div>

			</div>


      <div>
        <h4 className="licniPodaciVlasnika">Podaci donatora</h4>
      </div>

      <div className="container">
				<div>
					<p className="inputInfo">
						IME
					</p>
					<input
						type="text"
						value={payload.ime}
						onChange={(event) =>
							handleInputChange(event, "ime")
						}
					/>
					{error.ime && <p className="errorMessage">{error.ime}</p>}
				</div>
				<div>
					<p className="inputInfo">
						PREZIME
					</p>
					<input
						type="text"
						value={payload.prezime}
						onChange={(event) =>
							handleInputChange(event, "prezime")
						}
					/>
					{error.prezime && <p className="errorMessage">{error.prezime}</p>}
				</div>
				<div>
					<p className="inputInfo">BROJ TELEFONA</p>
					<input
						type="text"
						value={payload.telefon}
						onChange={(event) => handleInputChange(event, "telefon")}
					/>
				</div>

				<div>
					<p className="inputInfo">
						EMAIL
					</p>
					<input
						type="text"
						value={payload.email}
						onChange={(event) =>
							handleInputChange(event, "email")
						}
					/>
					{error.email && <p className="errorMessage">{error.email}</p>}
				</div>
			</div>



      <div className="usloviBtn">
				<div className="checkBoxContainer">
					<input
						id="checkbox"
						className="checkBox"
						type="checkbox"
						checked={payload.isChecked}
						onChange={event => handleInputChange(event, "isChecked")}
					/>{" "}
					<label htmlFor="checkbox">
						Prihvatam <Link to="/uslovi-koriscenja">uslove korišćenja</Link>
					</label>
				</div>
        {error.isChecked && <p className="errorMessage">{error.isChecked}</p>}
        <div>
          <button className="plati" onClick={handlePlatiClick}>
            Plati
          </button>
        </div>
      </div>
    </div>
  );
}
