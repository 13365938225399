import { BACKEND_HOST } from "constants/index.js"

export async function getActionsByType(type, limit) {
	try {
		const response = await fetch(`${BACKEND_HOST}/api/get-actions-by-status`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				status: type,
			})
		})

		const data = await response.json()

		if(data?.actions) {
			return {
				actions: data.actions.slice(0, limit || data.actions.length)
			}
		}

		return data
	} catch(e) {}

	return {
		actions: []
	}
}

export async function getActionById(id) {
	try {
		const response = await fetch(`${BACKEND_HOST}/api/get-action-by-id`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				id: id
			})
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		action: {}
	}
}

export async function createAction(payload) {
	try {
		const response = await fetch(`${BACKEND_HOST}/api/create-action`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		})

		const data = await response.json()

		return data
	} catch(e) {}

	return {
		message: "Došlo je do greške"
	}
}
