import { Link } from "react-router-dom";

import './style.css'

export default function LicniPodaciAkcija({ handleInputChange, payload }) {
  return (
    <div className="licniPodaciAkcija">
      <h2>Akcija</h2>
      <div>
        <div className="container-akcija1">
          <div>
            <p className="inputInfo">
              NAZIV AKCIJE<span>*</span>
            </p>{" "}
            <input className="inputAkcija1" onChange={e => handleInputChange(e?.target?.value, "actionName")}></input>
          </div>
          <div>
            <p className="inputInfo">
              ŽELJENA SREDSTVA<span>*</span>
            </p>{" "}
            <input
              placeholder="Upišite ciljanu sumu akcije "
              type="number"
              className="inputAkcija1"
							onChange={e => handleInputChange(e?.target?.value, "targetSum")}
            ></input>
          </div>
          <div className="inputSaObjasnjenjem">
            <p className="inputInfo">
              OBRAČUNATA SREDSTVA<span>*</span>
            </p>{" "}
            <input className="inputAkcija1" disabled value={payload.targetSumWithTax || 0}></input>
            <p className="objasnjenje">
              Iznos koji je naveden za prikupljanje uvećava se za 9% na ime
              troškova nastalih zbog nenaplaćenih donatorskih SMS poruka od
              strane operatera, bankarskih provizija, kursnih razlika.
            </p>
          </div>
        </div>
        <div className="container-akcija2">
          <div>
            <p className="inputInfo">
              OBLAST AKCIJE<span>*</span>
            </p>{" "}
            <input className="inputAkcija2" onChange={e => handleInputChange(e?.target?.value, "actionDomain")}></input>
          </div>
          <div>
            <p className="inputInfo">
              DATUM POKRETANJA AKCIJE <span>*</span>
            </p>{" "}
            <input className="inputAkcija2" type="date" onChange={e => handleInputChange(e?.target?.value, "startDate")}></input>
          </div>
          <div>
            <p className="inputInfo">
              DATUM ZAVRŠETKA AKCIJE <span>*</span>
            </p>{" "}
            <input className="inputAkcija2" type="date" onChange={e => handleInputChange(e?.target?.value, "endDate")}></input>
          </div>
          <div>
            <p className="inputInfo">GRAD I DRŽAVA REALIZACIJE AKCIJE<span>*</span></p>{" "}
            <input className="inputAkcija2" onChange={e => handleInputChange(e?.target?.value, "actionLocation")}></input>
          </div>
        </div>
        <div>
          <div className="container-akcija3">
						<div>
							<p className="inputInfo">
								ZAŠTO POKRECETE OVU AKCIJU?<span>*</span>
							</p>{" "}
							<textarea className="textareaAkcija" onChange={e => handleInputChange(e?.target?.value, "description")}></textarea>
						</div>
          </div>
          <div className="vrijemeRealizacije">
            <p className="inputInfo">
              KOLIKO VREMENA ĆE BITI POTREBNO DA SE VAŠA AKCIJA, PO PRIKUPLJANJU
              SREDSTAVA, REALIZUJE?<span>*</span>
            </p>{" "}
            <input className="inputAkcija3" type="number" onChange={e => handleInputChange(e?.target?.value, "timeToImplementAfterFunding")}></input>
            <p className="objasnjenje">
              Unesi očekivan broj dana potreban za realizaciju.
            </p>
          </div>
        </div>

        <div className="container-checkbox">
          <div>
            <input type="checkbox"  onChange={e => handleInputChange(!payload.volunteersNeeded, "volunteersNeeded")}/>{" "}
            <p className="inputInfo">
              POTREBNI VOLONTERI ZA REALIZACIJU AKCIJE
            </p>
          </div>
          <div>
            <input type="checkbox" onChange={e => handleInputChange(!payload.termsAndCondition, "termsAndCondition")} />{" "}
            <p className="inputInfo">
              PRIHVATAM{" "}
              <Link className="link" to="/uslovi-koriscenja">
                USLOVE KORIŠĆENJA
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
