import "./style.css";

export default function Opis() {
  return (
    <>
      <div className="opisFonda">
        <div className="opis" id="opis-fonda">
          <img className="logo" src="/images/logo.png" />
          <p className="homeOpisTekst">
            Humanitarno fond Hilal osnovan je 2012 godine, u Bijelom Polju kao
            dio Medzlisa islamske zajednice Bijelo Polje. Hilalove prve
            humanitarne aktivnosti vezu se za dzemat Rasovo, odakle krecu
            organizovane humanitarne aktivnosti. U pocetku rada Hilal fonda, rad
            je bio fokusiran na obezbjedjenje paketa hrane za najugrozenije
            bosnjacke porodice u Bijelom Polju. Sa protokom vremena,
            realizacijom mnogih humanitarnih aktivnosti Hilal postaje jedna vrlo
            vazna karika u zivotu ljudi na sjeveru Crne Gore ili Juznog
            Sandzaka. Kompletan rad vec svih 12 godina jeste na volonterskoj
            osnovi. Najveci cilj humanitarnog fonda Hilal jeste prezentovati i
            predstaviti Islam i islamsku poruku pomaganja, kroz praktican
            primjer na terenu, gdje smo pored onih paketa hrane, u obzir uzeli
            pomagati djecu sirocad/jetime, siromasne studente, stara i onemocala
            domacinstva, bolesne osobe, kao i mnoge druge posebne aktivnosti
            koje su u datom trenutku aktuelne. Posebno izdvajamo aktivnosti na
            izgradnji bunara i dzamija u africkoj drzavi Burkina Faso, gdje je
            do sada izgradjeno preko 200 bunara, 10 dzamija, kao i obnovljeno
            nekoliko ucionica u skolama.
          </p>
          <p className="homeOpisTekst">
            Humanitarni fond Hilal trenutno vodi brigu o 400 porodica iz Bijelog
            Polja, Pljevalja, Petnjice, Berana, Plava, Rozaja... gotovo da
            nemamo granica u radu kada je u pitanju Crna Gora i Sandzak. Posebnu
            pomoc od pocetka dali su nam nasi dobri ljudi iz Dijaspore,
            Njemacka, Luksemburg, Svajcarska, Belgija, Amerika, Francuska,
            Svedska i druge zemlje. Ramazanske i bajramske aktivnosti su
            posebne, u njima se pomogne vise stotina porodica na razne nacine.
            <br />
            <br /> Projekti za pomoc studentima su najznacajniji, mlada i sveza
            pamet, spremna da se bori za sebe i svoje za bolju buducnost. U
            svemu tome Islamska poruka i rad imaju svoju smisao. A to je
            najbitnije. Posebno se radujemo otvaranju i pustanju u funkciju
            Hilal vakuf humanitarnog centra, prvog ovakvog tipa u Islamskoj
            zajednici Crne Gore, u istoriji. Porjekat Hilal vakuf centra daje
            nam mnoge sanse i mogucnosti za organizovaniji i predaniji rad, na
            jacanju i osnazivanju veza sa nasim ljudima sirom svijata, citavom
            dijasporom.
          </p>
          <p className="homeOpisTekst">
            Kroz pomaganje djece jetima/sirocadi, pruzamo im sansu za koliko
            toliko zdravije i normalnije djetinjstvo. <br /> <br /> Upravni
            odbor Hilal fonda Izet Hadzibulic, Sejo Cindrak, Enis Avdic sa
            prosirenim sastavom Safet Hadzibulic i Samel Sabanovic, nastojat ce
            da bude jos vise na vezi i njenom ucvrscivanju, izmedju nasih rodnih
            krajeva i nase dijaspore. Kroz humanitarne, edukativne i
            prijateljske veze smatramo da cemo postici mnoge hairli rezultate,
            neophodne za ocuvanje sto zdravijeg i boljeg drustva u Crnoj Gori,
            Sandzaku i dijaspori.
            <br /> <br /> Jedno veliko hvala svima vama na dosadasnjoj podrsci.
            <br /> <br></br> “…onaj ko bude uradio koliko trun dobra, vidjet ce
            ga, a onaj ko bude uradio koliko trun zla, vidjet ce ga” (ez-Zilzāl,
            6 – 8) dovoljni kao podsticaj vjerniku da udjeljuje milostinju
            nadajuci se nagradi, pa makar se radilo o necem bezazlenom, oni
            takođe ulijevaju strah od posljedica grijesenja.
          </p>
        </div>
      </div>
    </>
  );
}
